import { reactive, computed } from 'vue'

import { HistoryData } from '@/core/classes/HistoryData.class'
import { SharedHistoryFilters } from '@/core/classes/SharedHistoryFilters.class'
import { LTLHistoryFilters } from '@/core/classes/LTLHistoryFilters.class'
import { FTLHistoryFilters } from '@/core/classes/FTLHistoryFilters.class'
import { ExpressHistoryFilters } from '@/core/classes/ExpressHistoryFilters.class'

import { LTL_TOKEN } from '@/core/constants/LTL.constants'
import { FTL_TOKEN } from '@/core/constants/FTL.constants'

import {
    LTLArrivingInvoiceT,
    LTLSendedInvoiceT,
    LTLShortInvoiceT,
    ShortLTLOrderT,
} from '@/core/types/LTL.types'
import { FTLOrderT, CreatedFTLBid } from '@/core/types/FTL.types'

import { InvoicesRealisation } from '@/core/realisations/Invoices.realisation'
import { CitiesRealisation } from '@/core/realisations/Cities.realisation'
import { FTLRealisation } from '@/core/realisations/FTL.realisation'
import { DeliveryRealisation } from '@/core/realisations/Delivery.realisation'

import { CityT } from '@/core/types/common.types'
import {
    FTLFiltersStateT,
    LTLFiltersStateT,
    ExpressFiltersStateT,
} from '@/core/types/History.types'
import { EXPRESS_TOKEN } from '@/core/constants/Express.constants'
import { DeliveryOrderT } from '@/core/types/Delivery.types'

// composable
import { useFile } from '@/common/composable/useFile'
import useNotifications from '@/common/composable/useNotifications'

export const historyFiltersState = reactive(new SharedHistoryFilters())
export const LTLFiltersState = reactive(new LTLHistoryFilters())
export const FTLFiltersState = reactive(new FTLHistoryFilters())
export const ExpressFiltersState = reactive(new ExpressHistoryFilters())

// getters
export const isLTL = computed(() => historyFiltersState.type.value === LTL_TOKEN)
export const isFTL = computed(() => historyFiltersState.type.value === FTL_TOKEN)
export const isEXPRESS = computed(() => historyFiltersState.type.value === EXPRESS_TOKEN)

export const isLTLInvoices = computed(() => {
    if (isLTL.value && historyFiltersState.data_type.value === 'invoices') return true
    return false
})

export const isLTLOrders = computed(() => {
    if (isLTL.value && historyFiltersState.data_type.value === 'orders') return true
    return false
})

export const isFTLBids = computed(() => {
    if (isFTL.value && historyFiltersState.data_type.value === 'bids') return true
    return false
})

export const isFTLOrders = computed(() => {
    if (isFTL.value && historyFiltersState.data_type.value === 'orders') return true
    return false
})

// history data state
export const LTLInvoicesData = reactive(new HistoryData<LTLShortInvoiceT>())
export const LTLArrivingInvoicesData = reactive(new HistoryData<LTLArrivingInvoiceT>())
export const LTLSendedInvoicesData = reactive(new HistoryData<LTLSendedInvoiceT>())
export const LTLOrdersData = reactive(new HistoryData<ShortLTLOrderT>())
export const FTLOrdersData = reactive(new HistoryData<FTLOrderT>())
export const FTLBidsData = reactive(new HistoryData<CreatedFTLBid>())
export const ExpressOrdersData = reactive(new HistoryData<DeliveryOrderT>())

export const filtersCitiesState = reactive({
    state: {
        is_loading: false,
    },
    data: [] as CityT[],
})

export const useHistory = () => {
    const notifications = useNotifications()
    const { saveFile } = useFile()

    const invoicesRealisation = new InvoicesRealisation()
    const citiesRealisation = new CitiesRealisation()
    const ftlRealisation = new FTLRealisation()
    const deliveryRealisation = new DeliveryRealisation()

    const setLTLFilters = (ltl_filters: LTLFiltersStateT) => {
        LTLFiltersState.invoiceStatus = ltl_filters.invoiceStatus
        LTLFiltersState.cod_payment = ltl_filters.cod_payment
        LTLFiltersState.date_range = ltl_filters.date_range
        LTLFiltersState.declared_price = ltl_filters.declared_price
        LTLFiltersState.payment_method = ltl_filters.payment_method
        LTLFiltersState.payment_type = ltl_filters.payment_type
        LTLFiltersState.receiver_city = ltl_filters.receiver_city
        LTLFiltersState.sender_city = ltl_filters.sender_city
        LTLFiltersState.shipment_type = ltl_filters.shipment_type
        LTLFiltersState.status = ltl_filters.status
        LTLFiltersState.sender = ltl_filters.sender
        LTLFiltersState.receiver = ltl_filters.receiver
        LTLFiltersState.archive = ltl_filters.archive
    }

    const setFTLFilters = (ftl_filters: FTLFiltersStateT) => {
        FTLFiltersState.sender_city = ftl_filters.sender_city
        FTLFiltersState.cubic_capacity = ftl_filters.cubic_capacity
        FTLFiltersState.date_range = ftl_filters.date_range
        FTLFiltersState.lifting_capacity = ftl_filters.lifting_capacity
        FTLFiltersState.loading_type = ftl_filters.loading_type
        FTLFiltersState.payment_type = ftl_filters.payment_type
        FTLFiltersState.points_count = ftl_filters.points_count
        FTLFiltersState.status = ftl_filters.status
        FTLFiltersState.temperature_regim = ftl_filters.temperature_regim
    }

    const setExpressFilters = (ftl_filters: ExpressFiltersStateT) => {
        ExpressFiltersState.date_range = ftl_filters.date_range
        ExpressFiltersState.status = ftl_filters.status
    }

    const loadLTLInvoices = async () => {
        try {
            LTLInvoicesData.state.is_loading = true

            const params = LTLFiltersState.getParams(
                LTLInvoicesData.pagination.current_page,
                historyFiltersState.items_count.value,
                historyFiltersState.search_query,
                historyFiltersState.search_type
            )

            if (params.type === 'arriving') {
                const response = await invoicesRealisation.getLTLArrivingInvoices({
                    ...params,
                    type: null,
                })

                LTLArrivingInvoicesData.data = response.data
                LTLArrivingInvoicesData.pagination.total_pages = 999
            } else if (params.type === 'sended') {
                const response = await invoicesRealisation.getLTLSendedInvoices({
                    ...params,
                    type: null,
                })

                LTLSendedInvoicesData.data = response.data
                LTLSendedInvoicesData.pagination.total_pages = 999
            } else {
                const response = await invoicesRealisation.getLTLInvoices(params)
                LTLInvoicesData.data = response.data
                LTLInvoicesData.pagination.total_pages = 999
            }

            LTLInvoicesData.state.is_failed = false
        } catch (error) {
            console.error(error)
            LTLInvoicesData.state.is_failed = true
        } finally {
            LTLInvoicesData.state.is_loading = false
        }
    }

    const exportExcelLTLInvoices = async () => {
        try {
            LTLInvoicesData.state.is_loading = true

            const params = LTLFiltersState.getParamsExcel(
                historyFiltersState.search_query,
                historyFiltersState.search_type,
                historyFiltersState.items_count.value
            )

            const response = await invoicesRealisation.exportExcelLTLInvoices(params)
            saveFile(response, 'LTL Накладные')
        } catch (error) {
            console.error(error)
            notifications.failure(
                {
                    group: 'create-module',
                    type: 'error',
                    title: 'Ошибка скачивания Excel',
                },
                error
            )
        } finally {
            LTLInvoicesData.state.is_loading = false
        }
    }

    const loadLTLOrders = async () => {
        try {
            LTLOrdersData.state.is_loading = true

            const params = LTLFiltersState.getParams(
                LTLOrdersData.pagination.current_page,
                historyFiltersState.items_count.value,
                historyFiltersState.search_query,
                historyFiltersState.search_type,
                true
            )

            const response = await invoicesRealisation.getLTLOrders(params)
            LTLOrdersData.data = response.data
            LTLOrdersData.pagination.total_pages = response.meta.last_page

            LTLOrdersData.state.is_failed = false
        } catch (error) {
            console.error(error)
            LTLOrdersData.state.is_failed = true
        } finally {
            LTLOrdersData.state.is_loading = false
        }
    }

    const formatData = (data: string | null | undefined) => {
        let result = data

        if (data) {
            result = data.split('-').reverse().join('-')
        }

        return result
    }

    const loadFTLOrders = async () => {
        try {
            FTLOrdersData.state.is_loading = true

            const params = FTLFiltersState.getParams(
                FTLOrdersData.pagination.current_page,
                historyFiltersState.items_count.value,
                historyFiltersState.search_query
            )

            const response = await invoicesRealisation.getFTLOrders({
                page: params.page,
                limit: params.limit,
                startTime: formatData(params.startTime),
                endTime: formatData(params.endTime),
                city_from_id: params.city_from_id,
                points_count: params.points_count,
                status: params.status,
                lifting_capacity: params.lifting_capacity,
                cubic_capacity: params.cubic_capacity,
                loading_type: params.loading_type,
                temperature_regim: params.temperature_regim,
                payment_type: params.payment_type,
                number: params.search as string,
            })

            FTLOrdersData.data = response.data
            FTLOrdersData.pagination.total_pages = 999

            FTLOrdersData.state.is_failed = false
        } catch (error) {
            console.error(error)
            FTLOrdersData.state.is_failed = true
        } finally {
            FTLOrdersData.state.is_loading = false
        }
    }

    const exportExcelFTLOrders = async () => {
        try {
            FTLOrdersData.state.is_loading = true

            const params = FTLFiltersState.getParamsExcel(
                historyFiltersState.search_query,
                historyFiltersState.search_type
            )

            const response = await invoicesRealisation.exportExcelFTLOrders({
                ...params,
                startTime: formatData(params.startTime),
                endTime: formatData(params.endTime),
            })
            saveFile(response, 'FTL Заказы')
        } catch (error) {
            console.error(error)
            notifications.failure(
                {
                    group: 'create-module',
                    type: 'error',
                    title: 'Ошибка скачивания Excel',
                },
                error
            )
        } finally {
            FTLOrdersData.state.is_loading = false
        }
    }

    const loadFTLBids = async () => {
        try {
            FTLBidsData.state.is_loading = true

            const params = FTLFiltersState.getParams(
                FTLBidsData.pagination.current_page,
                historyFiltersState.items_count.value,
                historyFiltersState.search_query
            )

            console.log(params)

            const response = await ftlRealisation.getBids({
                page: params.page,
                limit: params.limit,
                startTime: params.startTime,
                endTime: params.endTime,
                city_from_id: params.city_from_id,
                points_count: params.points_count,
                status: params.status,
                lifting_capacity: params.lifting_capacity,
                cubic_capacity: params.cubic_capacity,
                loading_type: params.loading_type,
                temperature_regim: params.temperature_regim,
                payment_type: params.payment_type,
                id: params.search as string,
            })

            FTLBidsData.data = response.data
            FTLBidsData.pagination.total_pages = 999

            FTLBidsData.state.is_failed = false
        } catch (error) {
            console.error(error)
            FTLBidsData.state.is_failed = true
        } finally {
            FTLBidsData.state.is_loading = false
        }
    }

    const exportExcelFTLBids = async () => {
        try {
            FTLBidsData.state.is_loading = true

            const params = FTLFiltersState.getParamsExcel(
                historyFiltersState.search_query,
                historyFiltersState.search_type
            )

            const response = await ftlRealisation.exportExcelFTLBids(params)
            saveFile(response, 'FTL Заявки')
        } catch (error) {
            console.error(error)
        } finally {
            FTLBidsData.state.is_loading = false
        }
    }

    const loadExpressOrders = async () => {
        try {
            ExpressOrdersData.state.is_loading = true

            const params = ExpressFiltersState.getParams(
                ExpressOrdersData.pagination.current_page,
                historyFiltersState.items_count.value
            )

            const response = await deliveryRealisation.getExpressOrders({
                page: params.page,
                limit: params.limit,
                createdAtFrom: params.createdAtFrom,
                createdAtTo: params.createdAtTo,
                status: params.status,
            })

            ExpressOrdersData.data = response.data
            ExpressOrdersData.pagination.total_pages = response.meta.last_page

            ExpressOrdersData.state.is_failed = false
        } catch (error) {
            console.error(error)
            ExpressOrdersData.state.is_failed = true
        } finally {
            ExpressOrdersData.state.is_loading = false
        }
    }

    const loadData = () => {
        if (isLTLInvoices.value) {
            loadLTLInvoices()
            return
        }

        if (isLTLOrders.value) {
            loadLTLOrders()
            return
        }

        if (isFTLBids.value) {
            loadFTLBids()
            return
        }

        if (isFTLOrders.value) {
            loadFTLOrders()
            return
        }

        if (isEXPRESS.value) {
            loadExpressOrders()
            return
        }
    }

    const exportExcel = () => {
        if (isLTLInvoices.value) {
            exportExcelLTLInvoices()
            return
        }

        if (isFTLBids.value) {
            exportExcelFTLBids()
            return
        }

        if (isFTLOrders.value) {
            exportExcelFTLOrders()
            return
        }
    }

    const loadCities = async () => {
        if (filtersCitiesState.data.length > 0) return

        try {
            filtersCitiesState.state.is_loading = true
            const citiesResponse = await citiesRealisation.getAllCities()
            filtersCitiesState.data = citiesResponse
        } catch (error) {
            console.error(error)
        } finally {
            filtersCitiesState.state.is_loading = false
        }
    }

    const resetFilters = () => {
        if (isLTL.value) LTLFiltersState.reset()
        if (isFTL.value) FTLFiltersState.reset()
        if (isEXPRESS.value) ExpressFiltersState.reset()
    }

    return {
        loadData,
        exportExcel,
        loadCities,
        LTLFiltersState,
        FTLFiltersState,
        ExpressFiltersState,
        //
        resetFilters,
        //
        setLTLFilters,
        setFTLFilters,
        setExpressFilters,
    }
}
