
import { defineComponent, onMounted, PropType } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import iRefresh from '@/assets/icons/Refresh.svg'

import ExpressStatus from '@/modules/HistoryNew/components/ExpressStatus/index.vue'
import ExpressLink from '@/modules/HistoryNew/components/ExpressLink/index.vue'

import { DeliveryOrderT } from '@/core/types/Delivery.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-refresh': iRefresh,
        'express-status': ExpressStatus,
        'express-link': ExpressLink,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        orders: {
            type: Array as PropType<DeliveryOrderT[]>,
            default: () => [],
        },
        withRefresh: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['refresh', 'mounted'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        onMounted(() => {
            emit('mounted')
        })

        // const getShortPath = (waypoints: WaypointI[]) => {
        //     return `${waypoints.find((w) => w.number === 0)?.city_name} - ${
        //         waypoints.find((w) => w.is_endpoint)?.city_name
        //     }`
        // }

        return { emitRefresh }
    },
})
