
import { defineComponent, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import LTLStatus from '@/modules/HistoryNew/components/LTLStatus/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'ltl-status': LTLStatus,
        'icon-refresh': iRefresh,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        invoices: {
            type: Array,
            default: () => [],
        },
        withRefresh: {
            type: Boolean,
            default: true,
        },
        inOrder: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['refresh', 'mounted'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        onMounted(() => {
            emit('mounted')
        })

        return { emitRefresh }
    },
})
