
import { defineComponent } from 'vue'

import SRadioButtons from '@/common/components/SRadioButtons/index.vue'

import {
    HISTORY_FILTERS_TYPES,
    HISTORY_FILTERS_LTL_STATUSES,
    HISTORY_FILTERS_LTL_TYPES,
} from '@/core/constants/History.constants'

import {
    historyFiltersState,
    LTLFiltersState,
    useHistory,
} from '@/modules/HistoryNew/composable/useHistory'

import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        's-radio-buttons': SRadioButtons,
    },
    setup() {
        const { loadData } = useHistory()

        const onViewTypeChange = (view_type: OptionT<string> | null) => {
            if (view_type && view_type.value === 'orders') {
                LTLFiltersState.type = null
            }
        }

        const onStatusChange = () => {
            LTLFiltersState.type = null
            loadData()
        }

        const onTypeChange = () => {
            LTLFiltersState.status = null
            loadData()
        }

        return {
            HISTORY_FILTERS_TYPES,
            HISTORY_FILTERS_LTL_STATUSES,
            HISTORY_FILTERS_LTL_TYPES,
            historyFiltersState,
            LTLFiltersState,
            onViewTypeChange,
            onStatusChange,
            onTypeChange,
            loadData,
        }
    },
})
