import moment from 'moment'

import { DateRangeT, OptionT } from '../types/common.types'
import { ExpressFiltersStateT, ExpressQueryFilters } from '../types/History.types'

export class ExpressHistoryFilters implements ExpressFiltersStateT {
    date_range: DateRangeT
    status: OptionT<string> | null

    constructor() {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.status = null
    }

    dateToString(date: Date | null): string | null {
        if (!date) return null
        return moment(date).format('YYYY-MM-DD')
    }

    getParams(page: number, limit: number): ExpressQueryFilters {
        const params: ExpressQueryFilters = {
            page,
            limit,
            status: this.status?.value,
            createdAtFrom: this.dateToString(this.date_range.date_from),
            createdAtTo: this.dateToString(this.date_range.date_to),
        }

        return params
    }

    reset(): void {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.status = null
    }
}
