
import { defineComponent, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import SButton from '@/common/components/SButton/index.vue'
import FTLOrderStatus from '@/common/components/FTLOrderStatus/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

import { POINTS_TOKENS } from '@/core/constants/common.constants'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'ftl-order-status': FTLOrderStatus,
        'icon-refresh': iRefresh,
        SButton,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        orders: {
            type: Array,
            default: () => [],
        },
        withRefresh: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['refresh', 'mounted', 'excel'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        const emitExcel = () => {
            emit('excel')
        }

        onMounted(() => {
            emit('mounted')
        })

        return { emitRefresh, emitExcel, POINTS_TOKENS }
    },
})
