import { DateRangeT, OptionT } from '../types/common.types'
import { LTLExcelParams, LTLFiltersStateT, LTLQueryFilters } from '../types/History.types'

import moment from 'moment'
import { useMaska } from '@/common/composable/useMaska'

export class LTLHistoryFilters implements LTLFiltersStateT {
    date_range: DateRangeT
    status: OptionT<string> | null
    invoiceStatus: OptionT<number> | null
    sender_city: OptionT<number> | null
    receiver_city: OptionT<number> | null
    shipment_type: OptionT<number> | null
    payment_type: OptionT<string> | null
    payment_method: OptionT<string> | null
    type: OptionT<string> | null
    cod_payment: number | null
    declared_price: number | null
    sender: string | null
    receiver: string | null
    archive = false

    constructor() {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.status = null
        this.invoiceStatus = null
        this.type = null
        this.sender_city = null
        this.receiver_city = null
        this.shipment_type = null
        this.payment_type = null
        this.payment_method = null
        this.cod_payment = null
        this.declared_price = null
        this.sender = null
        this.receiver = null
    }

    dateToString(date: Date | null): string | null {
        if (!date) return null
        // return moment(date).format('DD-MM-YYYY')
        return moment(date).format('YYYY-MM-DD')
    }

    getParams(
        page: number,
        limit: number,
        search_query: string | null,
        search_type: OptionT<string> | null,
        is_ltl_orders?: boolean
    ): LTLQueryFilters {
        const { unmaskNumbers } = useMaska()

        const params: LTLQueryFilters = {
            page,
            limit,
            search: !search_type || search_type.value === 'invoice_number' ? search_query : null,
            orderId: !search_type || search_type.value === 'orerId' ? search_query : null,
            dopInvoiceNumber:
                search_type && search_type.value === 'receiver_barcode' ? search_query : null,
            arrived: this.status?.value,
            type: this.type?.value,
            receiverCity: this.receiver_city?.value,
            shipmentType: this.shipment_type?.value,
            paymentType: this.payment_type?.value,
            paymentMethod: this.payment_method?.value,
            codPayment: this.cod_payment
                ? Number(unmaskNumbers(this.cod_payment.toString()))
                : null,
            declaredPrice: this.declared_price
                ? Number(unmaskNumbers(this.declared_price.toString()))
                : null,
            archive: this.archive ? 1 : null,
            statusId: this.invoiceStatus ? this.invoiceStatus.id : null,
            sender: this.sender ? this.sender : null,
            receiver: this.receiver ? this.receiver : null,
        }

        if (is_ltl_orders) {
            params.createdAtFrom = this.dateToString(this.date_range.date_from)
            params.createdAtTo = this.dateToString(this.date_range.date_to)
            params.senderCityId = this.sender_city?.value
        } else {
            params.startTime = this.dateToString(this.date_range.date_from)
            params.endTime = this.dateToString(this.date_range.date_to)
            params.senderCity = this.sender_city?.value
        }

        return params
    }

    getParamsExcel(
        search_query: string | null,
        search_type: OptionT<string> | null,
        limit: number | null
    ): LTLExcelParams {
        const { unmaskNumbers } = useMaska()

        const params: LTLExcelParams = {
            limit: limit ? 9999 : null,
            search: !search_type || search_type.value === 'invoice_number' ? search_query : null,
            dopInvoiceNumber:
                search_type && search_type.value === 'receiver_barcode' ? search_query : null,
            arrived: this.status?.value,
            type: this.type?.value,
            startTime: this.dateToString(this.date_range.date_from),
            endTime: this.dateToString(this.date_range.date_to),
            senderCity: this.sender_city?.value,
            receiverCity: this.receiver_city?.value,
            shipmentType: this.shipment_type?.value,
            paymentType: this.payment_type?.value,
            paymentMethod: this.payment_method?.value,
            codPayment: this.cod_payment
                ? Number(unmaskNumbers(this.cod_payment.toString()))
                : null,
            declaredPrice: this.declared_price
                ? Number(unmaskNumbers(this.declared_price.toString()))
                : null,
            statusId: this.invoiceStatus ? this.invoiceStatus.id : null,
            sender: this.sender ? this.sender : null,
            receiver: this.receiver ? this.receiver : null,
            archive: Number(this.archive),
        }

        return params
    }

    reset(): void {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.status = null
        this.invoiceStatus = null
        this.type = null
        this.sender_city = null
        this.receiver_city = null
        this.shipment_type = null
        this.payment_type = null
        this.payment_method = null
        this.cod_payment = null
        this.declared_price = null
        this.archive = false
        this.sender = null
        this.receiver = null
    }
}
