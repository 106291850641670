
import { defineComponent } from 'vue'

export default defineComponent({
    props: {
        id: {
            type: Number,
            required: true,
        },
    },
})
