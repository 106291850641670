import {
    HISTORY_FILTERS_ITEMS_COUNT_OPTIONS,
    HISTORY_FILTERS_ORDERS_TYPES,
    HISTORY_FILTERS_SEARCH_OPTIONS,
    HISTORY_FILTERS_TYPES,
} from '../constants/History.constants'
import { OptionT } from '../types/common.types'
import { HistoryStateT } from '../types/History.types'

export class SharedHistoryFilters implements HistoryStateT {
    type: OptionT<string>
    data_type: OptionT<string>
    items_count: OptionT<number>
    search_query: string | null
    search_type: OptionT<string> | null

    constructor() {
        this.type = HISTORY_FILTERS_ORDERS_TYPES[0]
        this.data_type = HISTORY_FILTERS_TYPES[0]
        this.items_count = HISTORY_FILTERS_ITEMS_COUNT_OPTIONS[0]
        this.search_query = null
        this.search_type = HISTORY_FILTERS_SEARCH_OPTIONS[0]
    }

    reset() {
        this.type = HISTORY_FILTERS_ORDERS_TYPES[0]
        this.data_type = HISTORY_FILTERS_TYPES[0]
        this.items_count = HISTORY_FILTERS_ITEMS_COUNT_OPTIONS[0]
        this.search_query = null
        this.search_type = HISTORY_FILTERS_SEARCH_OPTIONS[0]
    }
}
