
import { defineComponent, PropType, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'

import iRefresh from '@/assets/icons/Refresh.svg'

import { INVOICES_TOKENS } from '@/core/constants/common.constants'
import { ShortLTLOrderT } from '@/core/types/LTL.types'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-refresh': iRefresh,
    },
    props: {
        orders: {
            type: Array as PropType<ShortLTLOrderT[]>,
            default: () => [],
        },
        loading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['refresh', 'mounted'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        onMounted(() => {
            emit('mounted')
        })

        return { INVOICES_TOKENS, emitRefresh }
    },
})
