import moment from 'moment'

import { DateRangeT, OptionT } from '../types/common.types'
import { FTLExcelParams, FTLFiltersStateT, FTLQueryFilters } from '../types/History.types'

export class FTLHistoryFilters implements FTLFiltersStateT {
    date_range: DateRangeT
    status: OptionT<string> | null
    sender_city: OptionT<number> | null
    points_count: number | null
    lifting_capacity: OptionT<string> | null
    cubic_capacity: number | null
    loading_type: OptionT<string> | null
    temperature_regim: string | null
    payment_type: OptionT<string> | null

    constructor() {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.sender_city = null
        this.points_count = null
        this.status = null
        this.lifting_capacity = null
        this.cubic_capacity = null
        this.loading_type = null
        this.temperature_regim = null
        this.payment_type = null
    }

    dateToString(date: Date | null): string | null {
        if (!date) return null
        return moment(date).format('DD-MM-YYYY')
    }

    getParams(page: number, limit: number, search_query: string | null): FTLQueryFilters {
        const params: FTLQueryFilters = {
            page,
            limit,
            // invoiceNumber:
            //     !search_type || search_type.value === 'invoice_number' ? search_query : null,
            // dopInvoiceNumber:
            //     search_type && search_type.value === 'receiver_barcode' ? search_query : null,
            search: search_query,
            city_from_id: this.sender_city?.value,
            points_count: this.points_count,
            status: this.status?.value,
            lifting_capacity: this.lifting_capacity?.value,
            cubic_capacity: this.cubic_capacity,
            loading_type: this.loading_type?.value,
            temperature_regim: this.temperature_regim,
            payment_type: this.payment_type?.value,
            startTime: this.dateToString(this.date_range.date_from),
            endTime: this.dateToString(this.date_range.date_to),
        }

        return params
    }

    getIdForExcel = (search_query: string | null, search_type: OptionT<string> | null) => {
        if (
            !search_type ||
            search_type.value === 'invoice_number' ||
            (search_type && search_type.value === 'receiver_barcode')
        ) {
            return search_query
        } else return null
    }

    getParamsExcel(
        search_query: string | null,
        search_type: OptionT<string> | null
    ): FTLExcelParams {
        const params: FTLExcelParams = {
            invoiceNumber: null,
            dopInvoiceNumber: null,
            city_from_id: this.sender_city?.value,
            points_count: this.points_count,
            status: this.status?.value,
            lifting_capacity: this.lifting_capacity?.value,
            cubic_capacity: this.cubic_capacity,
            loading_type: this.loading_type?.value,
            temperature_regim: this.temperature_regim,
            payment_type: this.payment_type?.value,
            startTime: this.dateToString(this.date_range.date_from),
            endTime: this.dateToString(this.date_range.date_to),
            id: this.getIdForExcel(search_query, search_type),
            source: 1,
        }

        return params
    }

    reset(): void {
        this.date_range = {
            date_from: null,
            date_to: null,
        }
        this.sender_city = null
        this.points_count = null
        this.status = null
        this.lifting_capacity = null
        this.cubic_capacity = null
        this.loading_type = null
        this.temperature_regim = null
        this.payment_type = null
    }
}
