
import { computed, defineAsyncComponent, defineComponent, ref, watch } from 'vue'

import SSwitch from '@/common/components/SSwitch/index.vue'
import SSearchInput from '@/common/components/SSearchInput/index.vue'

import LTLFilters from '@/modules/HistoryNew/components/LTLFilters/index.vue'
import FTLFilters from '@/modules/HistoryNew/components/FTLFilters/index.vue'

import SAsyncModalLoader from '@/common/components/SAsyncModalLoader/index.vue'

import SModal from '@/common/components/SModal/index.vue'

const LTLAdditionalFilters = defineAsyncComponent({
    loader: () => import('@/modules/HistoryNew/components/LTLAdditionalFilters/index.vue'),
    loadingComponent: SAsyncModalLoader,
})

const FTLAdditionalFilters = defineAsyncComponent({
    loader: () => import('@/modules/HistoryNew/components/FTLAdditionalFilters/index.vue'),
    loadingComponent: SAsyncModalLoader,
})

const ExpressAdditionalFilters = defineAsyncComponent({
    loader: () => import('@/modules/HistoryNew/components/ExpressAdditionalFilters/index.vue'),
    loadingComponent: SAsyncModalLoader,
})

import iFilters from '@/assets/icons/Filters.svg'

import {
    HISTORY_FILTERS_ORDERS_TYPES,
    HISTORY_FILTERS_TYPES,
    HISTORY_FILTERS_SEARCH_OPTIONS,
    HISTORY_FILTERS_ITEMS_COUNT_OPTIONS,
    HISTORY_FTL_TYPES,
} from '@/core/constants/History.constants'

import { useModal } from '@/common/composable/useModal'
import {
    isFTL,
    isLTL,
    isEXPRESS,
    isLTLInvoices,
    isLTLOrders,
    isFTLBids,
    isFTLOrders,
    historyFiltersState,
    useHistory,
} from '@/modules/HistoryNew/composable/useHistory'

export default defineComponent({
    components: {
        's-switch': SSwitch,
        's-search-input': SSearchInput,
        'ltl-filters': LTLFilters,
        'ftl-filters': FTLFilters,
        'ltl-additional-filters': LTLAdditionalFilters,
        'ftl-additional-filters': FTLAdditionalFilters,
        'express-additional-filters': ExpressAdditionalFilters,
        's-modal': SModal,
        'icon-filters': iFilters,
    },
    setup() {
        const LTLAdditionalFiltersIsOpened = ref(false)
        const LTLAdditionalFiltersModal = useModal(LTLAdditionalFiltersIsOpened)

        const FTLAdditionalFiltersIsOpened = ref(false)
        const FTLAdditionalFiltersModal = useModal(FTLAdditionalFiltersIsOpened)

        const ExpressAdditionalFiltersIsOpened = ref(false)
        const ExpressAdditionalFiltersModal = useModal(ExpressAdditionalFiltersIsOpened)

        const openAdditionalFilters = () => {
            if (isLTL.value) {
                LTLAdditionalFiltersIsOpened.value = true
                return
            }

            if (isFTL.value) {
                FTLAdditionalFiltersIsOpened.value = true
                return
            }

            if (isEXPRESS.value) {
                ExpressAdditionalFiltersIsOpened.value = true
                return
            }
        }

        const { loadData } = useHistory()

        const changeLimitHandler = () => {
            setTimeout(loadData, 100)
        }

        const setBidsType = (option: { id: number; name: string; value: string }) => {
            if (option.value === 'FTL' && historyFiltersState.data_type.value !== 'orders') {
                historyFiltersState.data_type = HISTORY_FTL_TYPES[0]
            }

            if (option.value === 'LTL' && historyFiltersState.data_type.value !== 'orders') {
                historyFiltersState.data_type = HISTORY_FILTERS_TYPES[0]
            }
        }

        const computedPlaceholder = computed(() => {
            if (isLTLOrders.value) return 'Поиск по номеру заказа'
            if (isLTLInvoices.value) return 'Поиск по номеру накладной'
            if (isFTLOrders.value) return 'Поиск по номеру ТТН'
            if (isFTLBids.value) return 'Поиск по ID заявки'
            return null
        })

        const searchWithSelect = computed(() => {
            if (isLTLOrders.value || isLTLInvoices.value) return true
            return false
        })

        const getHistoryFiltersSearchOptions = computed(() => {
            return HISTORY_FILTERS_SEARCH_OPTIONS.filter((option) => {
                if (isLTLOrders.value) return option.id > 1
                else if (isLTLInvoices.value) return option.id < 3
            })
        })

        watch(
            () => isLTLInvoices.value,
            (value) => {
                let option = null
                if (value) {
                    option = HISTORY_FILTERS_SEARCH_OPTIONS.find((option) => option.id === 1)
                } else {
                    option = HISTORY_FILTERS_SEARCH_OPTIONS.find((option) => option.id === 3)
                }
                if (option) historyFiltersState.search_type = option
            }
        )

        return {
            HISTORY_FILTERS_ORDERS_TYPES,
            HISTORY_FILTERS_TYPES,
            HISTORY_FILTERS_SEARCH_OPTIONS,
            HISTORY_FILTERS_ITEMS_COUNT_OPTIONS,
            isFTL,
            isLTL,
            isEXPRESS,
            historyFiltersState,
            LTLAdditionalFiltersIsOpened,
            LTLAdditionalFiltersModal,
            FTLAdditionalFiltersIsOpened,
            ExpressAdditionalFiltersIsOpened,
            FTLAdditionalFiltersModal,
            ExpressAdditionalFiltersModal,
            openAdditionalFilters,
            loadData,
            changeLimitHandler,
            setBidsType,
            computedPlaceholder,
            searchWithSelect,
            getHistoryFiltersSearchOptions,
        }
    },
})
