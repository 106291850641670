<template>
    <div class="FTLFilters">
        <div class="FTLFilters__divider">
            <span class="FTLFilters__line" />
        </div>

        <s-radio-buttons
            :options="HISTORY_FTL_TYPES"
            :can-unselect="false"
            v-model:value="historyFiltersState.data_type"
        />
    </div>
</template>

<script>
import { defineComponent } from 'vue'

import SRadioButtons from '@/common/components/SRadioButtons/index.vue'

import { HISTORY_FTL_TYPES } from '@/core/constants/History.constants'

import { historyFiltersState } from '@/modules/HistoryNew/composable/useHistory'

export default defineComponent({
    components: {
        's-radio-buttons': SRadioButtons,
    },
    setup() {
        return {
            HISTORY_FTL_TYPES,
            historyFiltersState,
        }
    },
})
</script>

<style lang="scss" scoped src="./index.scss" />
