
import { computed, defineComponent, PropType } from 'vue'

import iCloseWhite from '@/assets/icons/CloseWhite.svg'
import { OptionT } from '@/core/types/common.types'

export default defineComponent({
    components: {
        'icon-close-white': iCloseWhite,
    },
    props: {
        value: {
            type: Object as PropType<OptionT<Record<string, unknown>>>,
            default: null,
        },
        canUnselect: {
            type: Boolean,
            default: true,
        },
        options: {
            type: Array as () => OptionT<Record<string, unknown>>[],
            required: true,
            default: () => [],
        },
    },
    emits: ['update:value', 'change'],
    setup(props, { emit }) {
        const activeValueIndex = computed(() => {
            const activeValue = props.value

            if (!activeValue || !activeValue.name) return -1
            if (!props.options || !props.options.length) return -1

            return props.options.findIndex(
                (option: OptionT<Record<string, unknown>>) => option.name === activeValue.name
            )
        })

        const selectOption = (option: OptionT<Record<string, unknown>>, optionIndex: number) => {
            if (optionIndex === activeValueIndex.value && props.canUnselect) {
                emit('update:value', null)
                emit('change', null)

                return
            }

            emit('update:value', option)
            emit('change', option)
        }

        return {
            selectOption,
            activeValueIndex,
        }
    },
})
