
import { defineComponent } from 'vue'

import iSearch from '@/assets/icons/Search.svg'

import { useDebounce } from 'vue-composable'

export default defineComponent({
    components: {
        'icon-search': iSearch,
    },
    props: {
        value: {
            type: String,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        }
    },
    emits: ['update:value', 'update:option', 'search'],
    setup(_, { emit }) {
        const callSearch = () => {
            emit('search')
        }

        const emitSearch = useDebounce(callSearch, 500)

        const updateValue = (event: InputEvent) => {
            const input: HTMLInputElement = event.target as HTMLInputElement

            emit('update:value', input.value)
            emitSearch()
        }

        return {
            updateValue,
            emitSearch,
        }
    },
})
