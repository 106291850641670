import { OptionT } from '../types/common.types'

export const EXPRESS_TOKEN = 'EXPRESS'
export const EXPRESS_ORDER_TYPE = { id: 3, name: 'Доставка за 3 часа', value: EXPRESS_TOKEN }

export const EXPRESS_STATUS_OPTIONS: OptionT<number>[] = [
    {
        id: 1,
        name: 'Заказ создан',
        value: 1,
    },
    {
        id: 2,
        name: 'Доставляется',
        value: 2,
    },
    {
        id: 3,
        name: 'Доставлен',
        value: 3,
    },
    {
        id: 4,
        name: 'Отменен',
        value: 4,
    },
]
