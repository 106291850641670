import { OptionT } from '@/core/types/common.types'

export const HISTORY_FILTERS_ORDERS_TYPES: OptionT<string>[] = [
    {
        id: 1,
        name: 'LTL',
        value: 'LTL',
    },
    {
        id: 2,
        name: 'FTL',
        value: 'FTL',
    },
    {
        id: 3,
        name: 'Доставка за 3 часа',
        value: 'EXPRESS',
    },
]

export const HISTORY_FILTERS_TYPES: OptionT<string>[] = [
    {
        id: 1,
        name: 'Накладные',
        value: 'invoices',
    },
    {
        id: 2,
        name: 'Заказы',
        value: 'orders',
    },
]

export const HISTORY_FTL_TYPES: OptionT<string>[] = [
    {
        id: 1,
        name: 'Заявки',
        value: 'bids',
    },
    {
        id: 2,
        name: 'Заказы',
        value: 'orders',
    },
]

export const HISTORY_FILTERS_SEARCH_OPTIONS: OptionT<string>[] = [
    {
        id: 1,
        name: 'Номер накладной',
        value: 'invoice_number',
    },
    {
        id: 3,
        name: 'Номер заказа',
        value: 'orerId',
    },
    {
        id: 2,
        name: 'По штрих коду заказчика',
        value: 'receiver_barcode',
    },
]

export const HISTORY_FILTERS_ITEMS_COUNT_OPTIONS: OptionT<number>[] = [
    {
        id: 1,
        name: '20',
        value: 20,
    },
    {
        id: 2,
        name: '30',
        value: 30,
    },
    {
        id: 3,
        name: '40',
        value: 40,
    },
    {
        id: 3,
        name: '100',
        value: 100,
    },
]

export const HISTORY_FILTERS_LTL_STATUSES: OptionT<string>[] = [
    {
        id: 1,
        name: 'Доставленные',
        value: '1',
    },
    {
        id: 2,
        name: 'Недоставленные',
        value: '0',
    },
]

export const HISTORY_FILTERS_LTL_TYPES: OptionT<string>[] = [
    {
        id: 1,
        name: 'Прибывающие',
        value: 'arriving',
    },
    {
        id: 2,
        name: 'Отправленные',
        value: 'sended',
    },
]

export const HISTORY_FILTERS_FTL_STATUSES: OptionT<string>[] = [
    {
        id: 1,
        name: 'Доставленные',
        value: 'delivered',
    },
    {
        id: 2,
        name: 'В процессе',
        value: 'inprogress',
    },
    {
        id: 3,
        name: 'Ищем лучшее предложение',
        value: 'searching',
    },
]
