import { HistoryDataT, HistoryDataStateT, HistoryDataPaginationT } from '../types/History.types'

export class HistoryData<T> implements HistoryDataT {
    public data: T[]
    public state: HistoryDataStateT
    public pagination: HistoryDataPaginationT

    constructor() {
        this.data = []
        this.state = {
            is_failed: false,
            is_loading: false,
        }
        this.pagination = {
            current_page: 1,
            total_pages: 1,
        }
    }
}
