
import { defineComponent, onMounted } from 'vue'

import SLoader from '@/common/components/SLoader/index.vue'
import iRefresh from '@/assets/icons/Refresh.svg'
import { WaypointI } from '@/core/types/FTL.types'
import SButton from '@/common/components/SButton/index.vue'

export default defineComponent({
    components: {
        's-loader': SLoader,
        'icon-refresh': iRefresh,
        SButton,
    },
    props: {
        title: {
            type: String,
            default: 'Title',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        bids: {
            type: Array,
            default: () => [],
        },
        withRefresh: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['refresh', 'mounted', 'excel'],
    setup(_, { emit }) {
        const emitRefresh = () => {
            emit('refresh')
        }

        onMounted(() => {
            emit('mounted')
        })

        const emitExcel = () => {
            emit('excel')
        }

        return { emitRefresh, emitExcel }
    },
})
