
import { defineComponent } from 'vue'

import HistoryFilters from '@/modules/HistoryNew/components/HistoryFilters/index.vue'
import InvoicesList from '@/modules/HistoryNew/components/InvoicesList/index.vue'
import ArrivingInvoicesList from '@/modules/HistoryNew/components/ArrivingInvoicesList/index.vue'
import SendedInvoicesList from '@/modules/HistoryNew/components/SendedInvoicesList/index.vue'
import OrdersList from '@/modules/HistoryNew/components/OrdersList/index.vue'
import FTLOrdersList from '@/modules/HistoryNew/components/FTLOrdersList/index.vue'
import FTLBidsList from '@/modules/HistoryNew/components/FTLBidsList/index.vue'
import ExpressOrdersList from '@/modules/HistoryNew/components/ExpressOrdersList/index.vue'

import SPagination from '@/common/components/SPagination/index.vue'

import {
    isLTLInvoices,
    isLTLOrders,
    LTLInvoicesData,
    LTLArrivingInvoicesData,
    LTLSendedInvoicesData,
    LTLOrdersData,
    FTLOrdersData,
    FTLBidsData,
    useHistory,
    isFTLBids,
    isFTLOrders,
    isEXPRESS,
    ExpressOrdersData,
    LTLFiltersState,
} from '@/modules/HistoryNew/composable/useHistory'

import useNotifications from '@/common/composable/useNotifications'
const NOTIFICATIONS_GROUP = 'create-module'

import { useTitle } from 'vue-composable'

export default defineComponent({
    components: {
        'history-filters': HistoryFilters,
        'invoices-list': InvoicesList,
        'arriving-invoices-list': ArrivingInvoicesList,
        'sended-invoices-list': SendedInvoicesList,
        'orders-list': OrdersList,
        'ftl-orders-list': FTLOrdersList,
        'ftl-bids-list': FTLBidsList,
        'express-orders-list': ExpressOrdersList,
        's-pagination': SPagination,
    },
    setup() {
        const { loadData, exportExcel } = useHistory()

        useTitle('История заказов')

        const notifications = useNotifications()

        const loadDataHandler = async () => {
            try {
                await loadData()
            } catch (error) {
                notifications.failure(
                    {
                        group: NOTIFICATIONS_GROUP,
                        type: 'error',
                        title: 'Ошибка получения данных',
                    },
                    error
                )
            }
        }

        return {
            isLTLInvoices,
            isLTLOrders,
            LTLInvoicesData,
            LTLArrivingInvoicesData,
            LTLSendedInvoicesData,
            LTLOrdersData,
            FTLOrdersData,
            FTLBidsData,
            loadDataHandler,
            exportExcel,
            isFTLBids,
            isFTLOrders,
            isEXPRESS,
            ExpressOrdersData,
            LTLFiltersState,
        }
    },
})
